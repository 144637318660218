import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    HomePageContainer as SourceHomePageContainer,
} from 'SourceRoute/HomePage/HomePage.container';

import './HomePage.override.style';

import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class HomePageContainer extends SourceHomePageContainer {
    // TODO implement logic
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
