import {
    CmsPageComponent as SourceCmsPageComponent,
} from 'SourceRoute/CmsPage/CmsPage.component';
import { isMobile } from 'Util/Mobile';
import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import { ReactElement } from 'Type/Common.type';

import './CmsPage.override.style';

export class CmsPageComponent extends SourceCmsPageComponent {
    render(): ReactElement {
        const {
            cmsPage,
            isBreadcrumbsActive,
            isLoading,
        } = this.props;
        const { page_width, title, content } = cmsPage;

        if (!isLoading && !title && !content) {
            return <NoMatch />;
        }

        return (
            <main
                block="CmsPage"
                mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
            >
                <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                    { this.renderHeading() }
                    <div block="CmsPage" elem="Content">
                        { this.renderContent() }
                    </div>
                </div>
            </main>
        );
    }
};

export default CmsPageComponent;
