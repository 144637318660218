import { DecorateHeading }  from '../../../../src/util/DecorateHeading';
class MiddleHeaderPlugin {
    removeMiddleHeaderOnComponentWillUnmount = (args, callback, instance) => {
        try {
            let headerBeforeBreadcrumbs = document.getElementById(
                "header-before-breadcrumbs"
            );
            // @ts-ignore
            headerBeforeBreadcrumbs.innerHTML = "";
        } catch (e) {
        }
        return callback(...args);
    };

    addCmsPageIdentificator = (args, callback, instance) => {
        document.body.classList.add('cms-page');
        return callback(...args);
    };

    removeCmsPageIdentificator = (args, callback, instance) => {
        document.body.classList.remove('cms-page');
        return callback(...args);
    };

    renderCategoryDetails = (args, callback, instance) => {
        let breadcrumbs = document.getElementsByClassName("Breadcrumbs");
        if (breadcrumbs.length) {
            try {
                document.getElementById("CategoryDetails")?.remove();
                let categoryDetails =
                    document.querySelectorAll(".CategoryDetails");
                const newNode = document.createElement("div");
                newNode.setAttribute("id", "CategoryDetails");
                const clone = categoryDetails[0].cloneNode(true);
                let headerBeforeBreadcrumbs = document.getElementById(
                    "header-before-breadcrumbs"
                );
                newNode.appendChild(clone);
                headerBeforeBreadcrumbs?.append(newNode);
                // categoryDetails = document.querySelectorAll(
                //     ".CategoryPage .CategoryDetails"
                // );
                // categoryDetails.forEach((el) => {
                //     el.remove();
                // });

                // setTimeout(() => {
                //     console.log("Delayed for 1 second.");
                //   }, 1000);
                DecorateHeading();
            } catch (e) {
            }
        }
        return callback(...args);
    };

    renderPageHeading = (args, callback, instance) => {
        const heading = callback(...args);
        try {
            let headerBeforeBreadcrumbs = document.getElementById(
                "header-before-breadcrumbs"
            );
            //@ts-ignore
            headerBeforeBreadcrumbs.innerHTML = "";
            const {
                type,
                props: {children},
            } = heading;
            const newDiv = document.createElement(type);
            if (type === 'h1') {
                newDiv.setAttribute("class", 'CmsPage-Heading');
            }
            // newDiv.setAttribute("class", className);
            const newContent = document.createTextNode(children);
            newDiv.appendChild(newContent);
            headerBeforeBreadcrumbs?.append(newDiv);
            // setTimeout(() => {
            //     console.log("Delayed for 1 second.");
            //     headerBeforeBreadcrumbs?.append(newDiv);
            // }, 1);

            DecorateHeading();

        } catch (e) {
        }
        return null;

    };

    renderCmsPageHeading = (args, callback, instance) => {
        // return callback(...args);
        setTimeout(() => {
            const heading = callback(...args);
            const {cmsPage: {content_heading}} = instance.props;
            try {
                let headerBeforeBreadcrumbs = document.getElementById(
                    "header-before-breadcrumbs"
                );
                //@ts-ignore
                headerBeforeBreadcrumbs.innerHTML = "";
                const {type} = heading;
                const newDiv = document.createElement(type);
                if (type === 'h1') {
                    newDiv.setAttribute("class", 'CmsPage-Heading');
                }
                const newContent = document.createTextNode(content_heading);
                newDiv.appendChild(newContent);
                headerBeforeBreadcrumbs?.append(newDiv);
            } catch (e) {
            }
        }, 300);
        return null;
    };
}

const {
    removeMiddleHeaderOnComponentWillUnmount,
    renderCategoryDetails,
    renderPageHeading,
    renderCmsPageHeading,
    addCmsPageIdentificator,
    removeCmsPageIdentificator
} = new MiddleHeaderPlugin();

export default {
    "Route/CategoryPage/Container": {
        "member-function": {
            // componentDidMount: removeMiddleHeaderOnComponentWillUnmount,
            // renderCategoryDetails: renderCategoryDetails,
        },
    },
    "Route/CategoryPage/Component": {
        "member-function": {
            componentDidMount: removeMiddleHeaderOnComponentWillUnmount,
            componentWillUnmount: removeMiddleHeaderOnComponentWillUnmount,
            renderCategoryDetails: renderCategoryDetails,
        },
    },
    "Route/CmsPage/Component": {
        "member-function": {
            renderHeading: renderCmsPageHeading,
            componentWillUnmount: removeCmsPageIdentificator,
        },
    },
    "Route/CmsPage/Container": {
        "member-function": {
            componentDidMount: addCmsPageIdentificator,
            componentWillUnmount: removeMiddleHeaderOnComponentWillUnmount,
            // componentWillUnmount: removeCmsPageIdentificator,
        },
    },
    "Route/ContactPage/Component": {
        "member-function": {
            componentDidMount: removeMiddleHeaderOnComponentWillUnmount,
            componentWillUnmount: removeMiddleHeaderOnComponentWillUnmount,
            renderHeading: renderPageHeading,
        },
    },
    "Route/CartPage/Component": {
        "member-function": {
            componentDidMount: removeMiddleHeaderOnComponentWillUnmount,
            componentWillUnmount: removeMiddleHeaderOnComponentWillUnmount,
            renderHeading: renderPageHeading,
        },
    },
    "Route/Checkout/Component": {
        "member-function": {
            // componentDidMount: removeMiddleHeaderOnComponentWillUnmount,
            // componentWillUnmount: removeMiddleHeaderOnComponentWillUnmount,
            // renderHeading: renderPageHeading,
        },
    },
    "Route/HomePage/Container": {
        "member-function": {
            componentDidMount: removeMiddleHeaderOnComponentWillUnmount
        },
    },
    "Route/KbCategoryPage/Component": {
        "member-function": {
            // componentDidMount: removeMiddleHeaderOnComponentWillUnmount,
            componentWillUnmount: removeMiddleHeaderOnComponentWillUnmount,
            renderHeading: renderPageHeading,
        },
    }
};
