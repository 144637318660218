import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    CmsPageContainer as SourceCmsPageContainer,
} from 'SourceRoute/CmsPage/CmsPage.container';

import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class CmsPageContainer extends SourceCmsPageContainer {
    updateBreadcrumbs(): void {
        const {
            toggleBreadcrumbs,
            isBreadcrumbsActive,
        } = this.props;

        toggleBreadcrumbs(isBreadcrumbsActive);
    }

    requestPage(): void {
        const { requestPage } = this.props;
        const params = this.getRequestQueryParams();
        const { id, identifier = '' } = params;
        const {
            actionName: {
                id: pageId = null,
                cmsPage: {
                    identifier: pageIdentifier = null,
                } = {},
            } = {},
        } = window;

        if (!id && !identifier) {
            return;
        }

        // fix homepage load on compiled version
        if (identifier === 'home') {
            requestPage(params);
            return;
        }
        // fix homepage load on compiled version

        // vvv check if cms page was already loaded from action
        if (
            id === pageId
            || identifier.replace(/^\/+/, '') === pageIdentifier
        ) {
            return;
        }

        requestPage(params);
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
